import React, {useState}     from 'react';
import PropTypes from 'prop-types';

function CamsSeoTeaser({text}) {
	const [showText, setShowText] = useState(false);
	const [showArrow, setShowArrow] = useState(false);

	const setRef = (ref) => {
		if (ref && ref.parentElement && ref.getBoundingClientRect().width > (ref.parentElement.getBoundingClientRect().width - 50)) {
			setShowArrow(true);
		}
	};

	if (!text) {
		return null;
	}

	return <div className={'cams__seo-teaser__wrapper' + (showText ? ' cams__seo-teaser__wrapper--show' : '')}>
			  <div className='cams__seo-teaser' dangerouslySetInnerHTML={{__html: text}} ref={setRef}></div>
			  {showArrow && <i onClick={() => setShowText(!showText)} className='icon -icon-single-arrow-down-line'></i>}
		   </div>;	
}

CamsSeoTeaser.propTypes = {
	text:  PropTypes.string,
};

CamsSeoTeaser.defaultProps = {
	text: '',
};

export default CamsSeoTeaser;